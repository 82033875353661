import React from 'react';
import character from '../../data/zzz_data.json';
import './guess.scss';

const Guess = ({ randomChampionIndex, selectedChampion }) => {

    function isEqual(array1, array2) {
        // Convert single objects to arrays with a single element
        array1 = Array.isArray(array1) ? array1 : [array1];
        array2 = Array.isArray(array2) ? array2 : [array2];
    
        let matchCount = 0;
    
        for (let i = 0; i < array1.length; i++) {
            if (isObjectInArray(array1[i], array2)) {
                matchCount++;
            }
        }
    
        let maxlength = Math.max(array1.length, array2.length);
    
        if (matchCount === 0) {
            return 0; // No objects in array1 match objects in array2
        } else if (matchCount === maxlength) {
            return 1; // All objects in array1 match objects in array2
        } else {
            return 2; // Some objects in array1 match objects in array2
        }
    }
    
    function isObjectInArray(obj, array) {
        for (let i = 0; i < array.length; i++) {
            // Check if the object in the array matches the given object
            if (isObjectEqual(obj, array[i])) {
                return true;
            }
        }
        return false;
    }
    
    function isObjectEqual(obj1, obj2) {
        // Assuming objects are simple and have no nested properties
        // You might need a more complex comparison for nested objects
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    const isNameMatch = character.data[selectedChampion].name === character.data[randomChampionIndex].name;
    const isgenderMatch = isEqual(character.data[selectedChampion].gender, character.data[randomChampionIndex].gender);
    const israrityMatch = isEqual(character.data[selectedChampion].rarity, character.data[randomChampionIndex].rarity);
    const isspecialtyMatch = isEqual(character.data[selectedChampion].specialty, character.data[randomChampionIndex].specialty);
    const iselementMatch = isEqual(character.data[selectedChampion].element, character.data[randomChampionIndex].element);
    const isattacktypeMatch = isEqual(character.data[selectedChampion].attacktype, character.data[randomChampionIndex].attacktype);
    const isoriginMatch = isEqual(character.data[selectedChampion].origin, character.data[randomChampionIndex].origin);
    const isDietMatch = isEqual(character.data[selectedChampion].diet, character.data[randomChampionIndex].diet);
    const isPointsMatch = isEqual(character.data[selectedChampion].points, character.data[randomChampionIndex].points);;

    return (
        <div className="guess_container">
            <div className={`guess_trait `}>
                <img src={"img/zzz/" + character.data[selectedChampion].thumbnail} alt="" />
                {/* <p>{character.data[selectedChampion].name}</p> */}
            </div>
            <div className={`guess_trait ${isgenderMatch === 2 ? 'guess_partial' : isgenderMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].gender)
                    ? character.data[selectedChampion].gender.join(", ")
                    : character.data[selectedChampion].gender}
                </p>
            </div>
            <div className={`guess_trait ${israrityMatch === 2 ? 'guess_partial' : israrityMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].rarity)
                    ? character.data[selectedChampion].rarity.join(", ")
                    : character.data[selectedChampion].rarity}
                </p>
            </div>
            <div className={`guess_trait ${isspecialtyMatch === 2 ? 'guess_partial' : isspecialtyMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].specialty)
                    ? character.data[selectedChampion].specialty.join(", ")
                    : character.data[selectedChampion].specialty}
                </p>
            </div>
            <div className={`guess_trait ${iselementMatch === 2 ? 'guess_partial' : iselementMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].element)
                    ? character.data[selectedChampion].element.join(", ")
                    : character.data[selectedChampion].element}
                </p>
            </div>
            <div className={`guess_trait ${isattacktypeMatch === 2 ? 'guess_partial' : isattacktypeMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].attacktype)
                    ? character.data[selectedChampion].attacktype.join(", ")
                    : character.data[selectedChampion].attacktype}
                </p>
            </div>
           <div className={`guess_trait ${isoriginMatch === 2 ? 'guess_partial' : isoriginMatch === 1 ? 'guess_correct' : 'guess_wrong'} borderright`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].origin)
                    ? character.data[selectedChampion].origin.join(", ")
                    : character.data[selectedChampion].origin}
                </p>
            </div>
    {/*  
            <div className={`guess_trait ${isDietMatch === 2 ? 'guess_partial' : isDietMatch === 1 ? 'guess_correct' : 'guess_wrong'}`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].diet)
                    ? character.data[selectedChampion].diet.join(", ")
                    : character.data[selectedChampion].diet}
                </p>
            </div>
            <div className={`guess_trait ${isPointsMatch === 2 ? 'guess_partial' : isPointsMatch === 1 ? 'guess_correct' : 'guess_wrong'} borderright`}>
                <p>
                    {Array.isArray(character.data[selectedChampion].points)
                    ? character.data[selectedChampion].points.join(", ")
                    : character.data[selectedChampion].points}
                </p>
            </div> */}
        </div>
    )
}

export default Guess;